<template>
  <b-card
    class="purchaseinbounditem-edit-wrapper"
  >
    <!-- form -->
    <b-form id="purchaseinbounditemForm" class="edit-form mt-2">
      <b-row>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="ID"
            label-for="inbound_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="inbound_id"
              size="sm"
              v-model="purchaseinbounditem.inbound_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="入库单编号"
            label-for="inbound_no"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="inbound_no"
              size="sm"
              v-model="purchaseinbounditem.inbound_no"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="是否选中"
            label-for="is_sel"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="is_sel"
              size="sm"
              v-model="purchaseinbounditem.is_sel"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="采购单ID"
            label-for="order_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="order_id"
              size="sm"
              v-model="purchaseinbounditem.order_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="采购单产品明细ID"
            label-for="orderitem_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="orderitem_id"
              size="sm"
              v-model="purchaseinbounditem.orderitem_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="商品ID"
            label-for="product_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="product_id"
              size="sm"
              v-model="purchaseinbounditem.product_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="分类ID"
            label-for="category_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="category_id"
              size="sm"
              v-model="purchaseinbounditem.category_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="分类名称"
            label-for="category_name"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="category_name"
              size="sm"
              v-model="purchaseinbounditem.category_name"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="品牌ID"
            label-for="brand_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="brand_id"
              size="sm"
              v-model="purchaseinbounditem.brand_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="品牌名称"
            label-for="brand_name"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="brand_name"
              size="sm"
              v-model="purchaseinbounditem.brand_name"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="名称"
            label-for="name"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="name"
              size="sm"
              v-model="purchaseinbounditem.name"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="是否赠品"
            label-for="is_gift"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="is_gift"
              size="sm"
              v-model="purchaseinbounditem.is_gift"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="计划入库数量"
            label-for="qty"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="qty"
              size="sm"
              v-model="purchaseinbounditem.qty"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="实际入库数量"
            label-for="trueqty"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="trueqty"
              size="sm"
              v-model="purchaseinbounditem.trueqty"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="状态"
            label-for="state"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="state"
              size="sm"
              v-model="purchaseinbounditem.state"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="添加时间"
            label-for="add_time"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="add_time"
              size="sm"
              v-model="purchaseinbounditem.add_time"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="修改时间"
            label-for="modify_time"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="modify_time"
              size="sm"
              v-model="purchaseinbounditem.modify_time"
            />
          </b-form-group>
        </b-col>
                <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            variant="primary"
            class="mr-1"
            @click="save"
          >
            保存
          </b-button>
          <b-button
            variant="outline-secondary"
            @click="cancel"
          >
            取消
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import purchaseinbounditemStore from './purchaseinbounditemStore'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor } from '@core/utils/filter'

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
  },
  data() {
    return {
      id: ref(0),
      purchaseinbounditem: ref({}),
    }
  },
  setup() {
    const toast = useToast()

    // Register module
    if (!store.hasModule('purchaseinbounditem')) store.registerModule('purchaseinbounditem', purchaseinbounditemStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('purchaseinbounditem')) store.unregisterModule('purchaseinbounditem')
    })

    const edit = function() {
      store.dispatch('purchaseinbounditem/edit', {id: this.id}).then(res => {
        this.purchaseinbounditem = res.data.data
      })
    }

    const view = function() {
      store.dispatch('purchaseinbounditem/view', {id: this.id}).then(res => {
        this.purchaseinbounditem = res.data.data
      })
    }

    const cancel = function() {
      this.$router.go(-1)
    }

    const save = function() {
      store.dispatch('purchaseinbounditem/save', this.purchaseinbounditem).then(res => {
        if (res.data.code==0){
          toast.success('数据已保存!')
          this.$router.push({ name: 'apps-purchaseinbounditem-list'});
        }else {
          toast.error("保存失败!")
        }

      })
    }

    return {
      edit,
      view,
      cancel,
      save,

      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
    }
  },
  created() {
    this.id = this.$route.query.id || 0;
    this.edit()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
